.load-button {
  height: 50px !important;
  width: 50px !important;
  color: gray;
}

.load-button:hover {
  color: black;
  cursor: pointer;
}

.invisible {
  display: none;
}

.hide {
  opacity: 0;
}

.load-button:hover + .hide {
  opacity: 1;
}

.load-container:hover .hide {
  opacity: 1;
}

.show-mobile {
  display: none;
}

@media (max-width: 480px) {
  .npl-mobile {
    padding-left: 0 !important;
  }

  .npt-mobile {
    padding-top: 0.5rem !important;
  }

  .hide-mobile {
    display: none;
  }

  .show-mobile {
    display: block;
  }
}
