.addLayer :hover {
  cursor: pointer;
  /* height: 250px; */
}

.addLayer {
  height: 80px !important;
  width: 80px !important;
  margin-left: auto;
  margin-right: auto;
}

.deleteLayer {
  /* height: 40px !important;
  width: 40px !important; */
  margin-top: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  color: rgba(85, 84, 84, 0.658);
}

.deleteLayer :hover {
  cursor: pointer;
  /* height: 250px; */
}

.hidden-delete {
  opacity: 0;
}

.hidden-delete {
  opacity: 0;
}

.layerInfo:hover .hidden-delete {
  opacity: 1;
}


.top-panel{
  margin-top: 10px;
}

.dims{
  width: 50px;
  height:30px;

}

.top-panel-text{
  margin-bottom: 0;
  /* padding: 0.25rem; */
  padding: 0.375rem 0.75rem;
}

.top-panel-span{
  display: flex;
  align-items: center;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  
}
